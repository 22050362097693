<template>
  <input
    class="input-hh"
    type="text"
    name="company"
    id="company"
    v-model="searchString"
    placeholder="Введите название вашей компании"
  />
  <div :style="[filteredItems.length ? {} : {opacity: 0}]" class="list-hh">
    <div
      v-for="item in filteredItems"
      v-bind:key="item.id"
      @click="select(item)"
    >
      <div class="logo">
        <img v-if="item.logo_urls" :src="item.logo_urls['90']" alt="" />
      </div>
      {{ item.name }}
    </div>
  </div>
  <div :style="[color ? {backgroundColor: color} : {opacity: 0}]" class="logo-wrap">
    <div class="logo-hh"><img :src="logoUrl" alt="" /></div>
  </div>
  <div v-if="color" class="logo2-wrap" :style="{ backgroundColor: color }">
    <div class="logo2-hh"><img :src="logoUrl" alt="" /></div>
  </div>

  <div :style="[color ? {} : {opacity: 0}]" class="qr">
    <div class="company-qr"><img :src="'https://app.refme.tech/api/company/'+companyId+'/qr.png'"></div>
    <div class="company-name">Просканируйте и установите приложение для <a :href="'https://app.refme.tech/'+companyId"><span>{{ companyName }}</span></a>
  </div>
  </div>
  <div v-if="color" class="company2-qr">
    <img :src="'https://app.refme.tech/api/company/'+companyId+'/qr.png'">
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";

export default {
  data() {
    return {
      searchString: null,
      items: [],
      companyName: null,
      companyId: null,
      logoUrl: null,
      isSugestVisible: false,
      filteredItems: [],
      color: null,
      qrUrl: null,
    };
  },
  watch: {
    searchString: debounce(function () {
      this.getCompanies();
    }, 500),
  },
  methods: {
    // getBg() {
    //   var self = this;
    //   // console.log(import.meta.env.VUE_APP_API_HOST);
    //   axios
    //     .post(`${process.env.VUE_APP_API_HOST}/api/color?url=${this.logoUrl}`)
    //     .then(function (response) {
    //       // console.log(response.data);
    //       self.color = response.data.color;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    select(item) {
      this.logoUrl = item.logo_urls["240"];
      this.companyName = item.name;
      this.companyId = item.id;
      this.filteredItems = [];
      const self = this;
      axios
        .get(`${process.env.VUE_APP_API_HOST}/api/company/${this.companyId}`)
        .then(function (response) {
          self.color = response.data.color;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    filterCompanies() {
      this.filteredItems = items.filter((item) => item.logo_urls);
    },
    getCompanies() {
      if (this.searchString.length >= 3) {
        // console.log(this.searchString);
        var self = this;
        // console.log(import.meta.env.VUE_APP_API_HOST);
        axios
          .get(
            `${process.env.VUE_APP_API_HOST}/api/search?q=${this.searchString}`
          )
          .then(function (response) {
            self.items = response.data.items;
            self.filteredItems = self.items.filter((item) => item.logo_urls);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.items = [];
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
